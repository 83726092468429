import { Refine } from "@pankod/refine-core";
import { notificationProvider, Layout, ConfigProvider } from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import "@pankod/refine-antd/dist/styles.min.css";
import "./App.css";
import { authProvider } from "./authProvider";
import dataProvider from "dataprovider/dataprovider";
import axios from "axios";

import Login from "pages/login";
import { resourceEndpoint } from "utils/endpoints";
import { IAuth } from "interfaces";

import { LeadList } from "pages/leads";
import { UserCreate, UserList } from "pages/users";

import 'dayjs/locale/fr';
import locale from "antd/lib/locale/fr_FR"
import { RappelList } from "pages/rappels";

import Dashboard from "pages/dashboard";
import Logo from "./assets/logo_lpp.svg";
import { ClockCircleOutlined, UnorderedListOutlined, UserOutlined } from "@ant-design/icons";
import { CustomSider } from "components/sider";


const {Link} = routerProvider;

const axiosInstance = axios.create ();

function App() {
  return (
    <ConfigProvider locale={locale}>
      <Refine
        DashboardPage={Dashboard}
        Sider={CustomSider}
        accessControlProvider={{
          can: ({ resource, action, params }) => {
            const authJson = localStorage.getItem('refine-auth');
            if (!authJson)
                return Promise.reject ("Session invalide");
            const auth = JSON.parse (authJson) as IAuth;
            if (auth.resources && auth.resources.includes (resource+action)) {
                return Promise.resolve({ can: true });
            }
            return Promise.resolve({
              can: false,
              reason: `Unauthorized ${action} on ${resource}`,
            });
          },
        }}
        Title={({ collapsed }) => (
          <Link to="/">
              {collapsed ? (
                  <img
                      src={Logo}
                      alt="Refine"
                      style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "24px 12px",
                      }}
                  />
              ) : (
                  <img
                      src={Logo}
                      alt="Refine"
                      style={{
                          width: "200px",
                          padding: "24px 12px",
                      }}
                  />
              )}
          </Link>
      )}
        routerProvider={routerProvider}
        notificationProvider={notificationProvider}
        Layout={Layout}
        dataProvider={dataProvider(resourceEndpoint, axiosInstance)}
        authProvider={authProvider}
        LoginPage={Login}
        resources={[
          {
            name: "leads",
            list: LeadList,
            options: {label: "Leads"},
            icon: <UnorderedListOutlined />
          },
          {
            name: "users",
            list: UserList,
            create: UserCreate,
            options: {label: "Utilisateurs"},
            icon: <UserOutlined />
          },
          {
            name: "rappels",
            list: RappelList,
            options: {label: "Rappels"},
            icon: <ClockCircleOutlined />
          },
        ]}
      />
    </ConfigProvider>
  );
}

export default App;
