import React, { useState } from "react";
import { useTitle, useNavigation, useLogout, CanAccess } from "@pankod/refine-core";
import { AntdLayout, Menu, useMenu, Grid, Icons } from "@pankod/refine-antd";
import { CSSProperties } from "react";

export const CustomSider: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const Title = useTitle();
  const { menuItems, selectedKey } = useMenu();
  const breakpoint = Grid.useBreakpoint();
  const { push } = useNavigation();
  const {mutate: logout} = useLogout ()

  const isMobile = !breakpoint.lg;

  return (
    <AntdLayout.Sider
      collapsible
      collapsedWidth={isMobile ? 0 : 80}
      collapsed={collapsed}
      breakpoint="lg"
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}
    >
      {Title && <Title collapsed={collapsed} />}
      <Menu
        selectedKeys={[selectedKey]}
        mode="inline"
        onClick={({ key }) => {
          if (!breakpoint.lg) {
            setCollapsed(true);
          }
          if (key === "logout") {
            logout ();
            return;
          }
          push(key as string);
        }}
      >
        {menuItems.map(({ icon, label, route, name }) => {
          const isSelected = route === selectedKey;
          return (
            <CanAccess
              key={route}
              resource={name.toLowerCase()}
              action="list">
              <Menu.Item
                style={{
                  fontWeight: isSelected ? "bold" : "normal"
                }}
                key={route}
                icon={icon}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  {label}
                  {!collapsed && isSelected && <Icons.RightOutlined />}
                </div>
              </Menu.Item>
            </CanAccess>
          );
        })}
        <Menu.Divider />
        <Menu.Item key="logout" icon={<Icons.LogoutOutlined />} >
            Déconnexion
        </Menu.Item>
      </Menu>
    </AntdLayout.Sider>
  );
};

const antLayoutSider: CSSProperties = {
    position: "relative",
};
const antLayoutSiderMobile: CSSProperties = {
    position: "fixed",
    height: "100vh",
    zIndex: 999,
};
