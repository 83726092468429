import React from "react";
import { useList } from "@pankod/refine-core";
import { ILead, ITrackingStepLPP } from "interfaces";
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    TimeScale,
    TimeSeriesScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import moment from "moment";
import "moment/locale/fr";
moment.locale ("fr");

ChartJS.register(ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    TimeSeriesScale,
);

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: true,
            text: `Click tunnels`,
        },
        tooltip: {
            displayColors: false,
            callbacks: {
                label: (context: any) => "Etape validée " + context.parsed.y + " fois",
            }
        }
    },
};

const Dashboard = () => {
    const { data: resultLead } = useList<ILead>({
        resource: "leads",
    });
    const { data: resultTracking } = useList<ITrackingStepLPP>({
        resource: "trackings",
        config: {
            filters: [
                {
                    field: "site",
                    operator: "eq",
                    value: "ma-lpp.ch"
                }
            ]
        }
    });

    const leads = resultLead?.data;
    const trackings = resultTracking?.data;
    const trackingR: ITrackingStepLPP[] = [];
    const trackingP: ITrackingStepLPP[] = [];
    trackings?.forEach(t => {
        if (t.tunnel === "Recherche") {
            trackingR.push(t);
        }
        if (t.tunnel === "Placement") {
            trackingP.push(t);
        }
    })
    const dataTR = trackingR.map(t => ({ labelStep: t.labelstep, count: t.count, step: t.step }))
    dataTR.sort((a, b) => parseInt(a.step, 10) - parseInt(b.step, 10))
    const dataLineTrackR = {
        labels: dataTR.map(d => d.labelStep),
        datasets: [
            {
                label: "Recherche",
                data: dataTR.map(d => d.count),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
        ],
    };

    const dataTP = trackingP.map(t => ({ labelStep: t.labelstep, count: t.count, step: t.step }))
    dataTP.sort((a, b) => parseInt(a.step, 10) - parseInt(b.step, 10))
    const dataLineTrackP = {
        labels: dataTP.map(d => d.labelStep),
        datasets: [
            {
                label: "Placement",
                data: dataTP.map(d => d.count),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
        ],
    };

    let ddata: Record<string, { type: string, value: number }> = {};
    leads?.forEach(l => {
        switch (l.statut) {
            case "Rien à faire":
                ddata["rafaire"] = { type: 'Rien à faire', value: (ddata["rafaire"]?.value ?? 0) + 1 }
                break;
            case "Rendez-vous fixé":
                ddata["rdv"] = { type: 'Rendez-vous fixé', value: (ddata["rdv"]?.value ?? 0) + 1 }
                break;
            case "A rappeler":
                ddata["arapp"] = { type: 'A rappeler', value: (ddata["arapp"]?.value ?? 0) + 1 }
                break;
            case "Messagerie":
                ddata["msg"] = { type: 'Messagerie', value: (ddata["msg"]?.value ?? 0) + 1 }
                break;
            case "Faux numéro":
                ddata["fnum"] = { type: 'Faux numéro', value: (ddata["fnum"]?.value ?? 0) + 1 }
                break;
            default:
                ddata["ntraiter"] = { type: 'Non traîté', value: (ddata["ntraiter"]?.value ?? 0) + 1 }
                break;
        }
    });
    const dataStatusLeads = Object.keys(ddata).map((key) => ddata[key]);
    const dataPieChartLeads = {
        labels: dataStatusLeads.map(l => l.type),
        datasets: [
            {
                label: 'Statut des leads',
                data: dataStatusLeads.map(l => l.value),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const dataPieChartLeadsPerTunnel = {
        labels: ["Recherche", "Placement"],
        datasets: [
            {
                label: 'Leads par tunnel',
                data: [leads?.filter(l => l.type === "recherche avoirs").length, leads?.filter(l => l.type === "placement avoirs").length],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    //Charts nb leads per day
    const dayCounts = leads?.reduce(function (result : Record<string, number>, order) {
        var day = moment(order.createdAt).format("YYYY-MM-DD");
        if (!result[day]) {
            result[day] = 0;
        }
        result[day]++;
        return result;
    }, {}) ?? [];

    let dataTSC : {x: string, y: number}[] = [];
    Object.entries(dayCounts).forEach(([key, value]) => {
        dataTSC.push ({x: key, y: value})
    });
    dataTSC.sort ((a,b) => moment (a.x).valueOf () - moment (b.x).valueOf ())
    dataTSC = dataTSC.filter (d => moment (d.x) > moment().hour (0).subtract(8,'d'))
    const dataTimeSeriesChart = {
        datasets: [{
            data: dataTSC,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        }],
    };
    const optionsTimeSeriesChart = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: "timeseries",
                time: {
                    unit: 'day',
                    tooltipFormat: 'dddd D MMMM',
                    displayFormats: {
                        day: 'ddd D MMM'
                    },
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: `Nombre de nouveaux leads sur les 7 derniers jours`,
            },
            tooltip: {
                displayColors: false,
                callbacks: {
                    label: (context: any) => context.parsed.y + " nouveaux leads",
                }
            }
        },
    }

    return (
        <>
            {leads && leads.length ?
                <div style={{ display: "flex", marginBottom: 30 }}>
                    <div style={{ position: "relative", height: "30vh", width: "50vw" }}>
                        <Doughnut data={dataPieChartLeads} options={{ maintainAspectRatio: false }} />
                    </div>
                    <div style={{ position: "relative", height: "30vh", width: "50vw" }}>
                        <Doughnut data={dataPieChartLeadsPerTunnel} options={{ maintainAspectRatio: false }} />
                    </div>
                </div>
                : null}
            {leads && leads.length ?
                <div style={{ position: "relative", height: 400, width: "80vw" }}>
                    {/* @ts-ignore */}
                    <Line options={optionsTimeSeriesChart} data={dataTimeSeriesChart} />
                </div>
                : null
            }
            {trackingR.length ?
                <div style={{ position: "relative", height: 400, width: "80vw", marginBottom: 30 }}>
                    <Line options={{ ...options, plugins: { ...options.plugins, title: { display: true, text: "Tunnel Recherche" } } }} data={dataLineTrackR} />
                </div>
                : null}
            {trackingP.length ?
                <div style={{ position: "relative", height: 400, width: "80vw" }}>
                    <Line options={{ ...options, plugins: { ...options.plugins, title: { display: true, text: "Tunnel Placement" } } }} data={dataLineTrackP} />
                </div>
                : null
            }
        </>)
}

export default Dashboard;