import { IResourceComponentsProps, useAuthenticated, useGetIdentity, useShow } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  FilterDropdown,
  FilterDropdownProps,
  ShowButton,
  Checkbox,
  Radio,
  Tooltip,
  Space,
} from "@pankod/refine-antd";

import { WarningOutlined } from "@ant-design/icons";

import { ILead } from "interfaces";
import NumField from "components/fields/NumField";
import TypeField from "components/fields/TypeField";
import { red } from "@ant-design/colors";
import React, { useState } from "react";
import dayjs from "dayjs";

import "dayjs/locale/fr";
import HeaderCustom from "components/header";
import { statutsLeads } from "utils/statutleads";
import ModalLead from "components/modals/ModalLead";

export const LeadList: React.FC<IResourceComponentsProps> = () => {
  const { data: identity } = useGetIdentity();
  const {
    refetch: checkAuth,
  } = useAuthenticated();

  const paramsUrl = new URLSearchParams(window.location.search);
  const { tableProps, sorter, tableQueryResult } = useTable<ILead>({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    permanentFilter: [
    ],
    initialPageSize: parseInt(paramsUrl.get("pageSize") ?? "100", 10),
    initialCurrent: parseInt(paramsUrl.get("current") ?? "1", 10)
  });

  const leads = tableQueryResult.data?.data;
  const countLeadsToday = leads?.filter(l => dayjs(l.createdAt) > dayjs().hour(0).minute(0).second(0)).length;

  const typeMapping: Record<string, string> = {
    ["recherche avoirs"]: "Recherche d'avoirs",
    ["placement avoirs"]: "Placement d'avoirs",
  }

  const [visibleShowModal, setVisibleShowModal] = useState<boolean>(false);

  const { queryResult, setShowId, showId } = useShow<ILead>();

  const { data: showQueryResult } = queryResult;
  const record = showQueryResult?.data;

  return (
    <>
      <HeaderCustom>Bienvenue <b>{identity?.prenom}</b>, aujourd'hui <span className="header-rappels">{`${identity?.nbRappelsUrgents} ${identity?.nbRappelsUrgents && identity?.nbRappelsUrgents > 1 ? "rappels" : "rappel"}`}</span>  à traîter. <b>{countLeadsToday}</b> nouveaux leads aujourd'hui</HeaderCustom>
      <List title="Leads">
        <Table {...tableProps} rowKey="_id">
          <Table.Column
            dataIndex="nom"
            key="nom"
            title="Nom"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="prenom"
            key="prenom"
            title="Prénom"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="telephone"
            key="telephone"
            title="Téléphone"
            render={(value, record: ILead) => <NumField value={value} record={record} />}
          />
          <Table.Column
            dataIndex="datenaissance"
            key="datenaissance"
            title="Age"
            render={(value, record: ILead) => <TextField value={`${Math.trunc(dayjs().diff(dayjs(value, 'YYYY'), 'years', true))}`} />}
            sorter
          />
          <Table.Column
            dataIndex="type"
            key="type"
            title="Type de lead"
            render={(value: string) => <TextField value={typeMapping[value]} />}
            filterDropdown={(props: FilterDropdownProps) => {
              return (
                <FilterDropdown {...props}>
                  <Radio.Group name="type">
                    <Radio value={"recherche avoirs"}>Recherche d'avoirs</Radio>
                    <Radio value={"placement avoirs"}>Placement d'avoirs</Radio>
                  </Radio.Group>
                </FilterDropdown>
              )
            }}
          />
          <Table.Column
            dataIndex="statut"
            key="statut"
            title="Statut"
            render={(value: string) => <TypeField value={value} />}
            filterDropdown={(props: FilterDropdownProps) => {
              return (
                <FilterDropdown {...props}>
                  <Checkbox.Group name="type">
                    {statutsLeads.map(s => <Checkbox value={s.name}>{s.name}</Checkbox>)}
                  </Checkbox.Group>
                </FilterDropdown>
              )
            }}
          />
          <Table.Column
            dataIndex="extra"
            key="extra.anneetravaillees"
            title="Années travaillées"
            render={value => <TextField value={value.anneetravaillees ?? "-"} />}
            sorter
          />
          <Table.Column
            dataIndex="createdAt"
            key="createdAt"
            title="Date"
            render={(value) => <DateField value={value} format="DD/MM/YYYY HH:mm" />}
            defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
            sorter
          />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_, record: ILead) =>
              <Space>
                <ShowButton hideText recordItemId={record._id}
                  onClick={() => {
                    setShowId(record._id);
                    setVisibleShowModal(true);
                    checkAuth();
                  }} />
                {record.doublonip &&
                  <Tooltip title="Doublon IP">
                    <WarningOutlined style={{ color: red.primary, fontSize: "1.4rem" }} />
                  </Tooltip>
                }
              </Space>
            }
          />
        </Table>
      </List>
      <ModalLead
        visible={visibleShowModal}
        onCancel={() => setVisibleShowModal (false)}
        record={record}
        showId={showId}
      />
    </>
  );
};
