import React from "react";
import { PageHeader } from "antd";

interface Props {
}

const HeaderCustom : React.FC<Props> = ({children}) => {
    return (
        <PageHeader className="custom-header">
            <div>
                {children}
            </div>
        </PageHeader>
    )
}

export default HeaderCustom;