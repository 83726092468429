import { AuthProvider } from "@pankod/refine-core";
import { IAuth } from "interfaces";
import { authEndpoint } from "utils/endpoints";

export const TOKEN_KEY = "refine-auth";

export const authProvider: AuthProvider = {
  login: async ({ email, password, code }) => {
    try {
      const codeint = parseInt (code, 10);
      const request = new Request(authEndpoint + "/login", {
          method: 'POST',
          body: JSON.stringify({ email, password, code: codeint }),
          headers: new Headers({ 'Content-Type': 'application/json' }),
      });
      const response = await fetch (request);
      const result = await response.json () as IAuth;
      if (result.error) {
        throw new Error (result.message);
      }
      localStorage.setItem(TOKEN_KEY, JSON.stringify(result));
      return Promise.resolve();
    } catch (error : any) {
      return Promise.reject ({
        name: "Connexion échoué",
        message: error.message ?? "Erreur lors de la connexion"
      })
    }
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    return Promise.resolve();
  },
  checkError: (error) => {
    if (error.statusCode === 401) {
      localStorage.removeItem(TOKEN_KEY);
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      const auth = JSON.parse (token) as IAuth;
      const request = new Request(authEndpoint + "/verify", {
          method: 'POST',
          body: JSON.stringify({ token: auth.token }),
          headers: new Headers({ 'Content-Type': 'application/json' }),
      });
      const response = await fetch (request);
      const data = await response.json ();
      if (data.message && (data.message === "Token manquant" || data.message === "Auth expirée")) {
        return Promise.reject (new Error ("Session expirée"))
      }
      localStorage.setItem(TOKEN_KEY, JSON.stringify(data));
      return Promise.resolve();
    }
    return Promise.reject(new Error ("Token manquant"));
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }
    const auth = JSON.parse (token) as IAuth;
    return Promise.resolve(auth);
  },
};
