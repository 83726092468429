import { IResourceComponentsProps, useAuthenticated, useGetIdentity, useShow, useUpdate } from "@pankod/refine-core";
import {
    List,
    Table,
    TextField,
    useTable,
    ShowButton,
    TagField,
    DateField,
    Space,
    Button
} from "@pankod/refine-antd";

import { IAuth, ILead, IRappel, IUser } from "interfaces";
import { CheckOutlined } from "@ant-design/icons";
import ModalLead from "components/modals/ModalLead";
import { useState } from "react";

export const RappelList: React.FC<IResourceComponentsProps> = () => {
    const { data: identity } = useGetIdentity<IAuth>();
    const {
        refetch: checkAuth,
    } = useAuthenticated();

    const paramsUrl = new URLSearchParams(window.location.search);
    const { tableProps } = useTable<IUser>({
        initialSorter: [
            {
                field: "date",
                order: "asc",
            },
        ],
        permanentFilter: [
            {
                field: "iduser",
                operator: "eq",
                value: identity?.id ?? ""
            },
            {
                field: "statut",
                operator: "ne",
                value: 1
            }
        ],
        initialPageSize: parseInt(paramsUrl.get("pageSize") ?? "50", 10),
        initialCurrent: parseInt(paramsUrl.get("current") ?? "1", 10)
    });

    const { mutate: update } = useUpdate<IRappel>();
    const validateRappel = (id: string) => {
        update({
            resource: "rappels",
            id: id,
            values: { statut: 1 }
        });
        checkAuth();
    }

    const [visibleShowModal, setVisibleShowModal] = useState<boolean>(false);
    const { queryResult, setShowId, showId } = useShow<ILead>({resource: "leads"});
    const { data: showQueryResult } = queryResult;
    const lead = showQueryResult?.data;

    return (
        <>
            <List title="Rappels">
                <Table {...tableProps} rowKey="_id">
                    <Table.Column
                        dataIndex="date"
                        key="date"
                        title="Date rappel"
                        render={(value) => <DateField value={value} format="DD/MM/YYYY HH:mm" />}
                    />
                    <Table.Column
                        dataIndex="comment"
                        key="comment"
                        title="Commentaire"
                        render={(value) => <TextField value={value} />}
                    />
                    <Table.Column
                        dataIndex="createdAt"
                        key="createdAt"
                        title="Date de création"
                        render={(value) => <DateField value={value} format="DD/MM/YYYY HH:mm" />}
                    />
                    <Table.Column
                        dataIndex="statut"
                        key="statut"
                        title="Statut"
                        render={(value) => <TagField value={value ? "Traité" : "A rappeler"} />}
                    />
                    <Table.Column
                        title="Actions"
                        dataIndex="actions"
                        render={(_, record: IRappel) =>
                        (<Space>
                            <ShowButton hideText recordItemId={record._id}
                                ignoreAccessControlProvider={record.idlead ? true : false}
                                onClick={() => {
                                    setShowId(record.idlead);
                                    setVisibleShowModal(true);
                                    checkAuth();
                                }} />
                            <Button icon={<CheckOutlined />} type="primary" onClick={() => validateRappel(record._id)} />
                        </Space>)
                        }
                    />
                </Table>
            </List>
            <ModalLead
                visible={visibleShowModal}
                onCancel={() => setVisibleShowModal (false)}
                record={lead}
                showId={showId}
            />
        </>
    );
};
