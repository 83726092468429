import { Create, Form, Input, useForm } from "@pankod/refine-antd";
import { IUser } from "interfaces";

export const UserCreate: React.FC = () => {
    const { formProps, saveButtonProps } = useForm <IUser>();

    return <Create title="Créer utilisateur" saveButtonProps={saveButtonProps}>
        <Form {...formProps} layout="vertical">
            <Form.Item label="Nom" name="name">
                <Input />
            </Form.Item>
            <Form.Item label="Prénom" name="firstname">
                <Input />
            </Form.Item>
            <Form.Item label="E-mail" name="email">
                <Input />
            </Form.Item>
            <Form.Item label="Téléphone" name="phone">
                <Input />
            </Form.Item>
            <Form.Item label="Mot de passe" name="password">
                <Input />
            </Form.Item>
        </Form>
    </Create>;
};