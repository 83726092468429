import { ClockCircleOutlined, MailOutlined, MessageOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Input, Tooltip } from "@pankod/refine-antd";
import { ICommentaire } from "interfaces";
import React, { CSSProperties, useState } from "react";

interface Props {
    onAddComment: (comment: string) => void;
    commentaires: ICommentaire[];
    style?: CSSProperties;
    setStatut?: (statut : string) => void;
    setRappeler?: () => void;
}

const ChatContainer : React.FC<Props> = ({children, style, onAddComment, commentaires, setStatut = (s : string) => {}, setRappeler = () => {}}) => {
    const [comment, setComment] = useState("");

    const _onAddComment = () => {
        if (!comment.trim ().length) {
            return;
        }
        onAddComment (comment);
        setComment ("");
    }

    const _onMessagerie = () => {
        onAddComment ("Messagerie");
        setStatut ("Messagerie");
    }

    return (
        <div style={style} className="chat-ctn">
            <div className="chat-msgsctn">
                <div className="chat-msgctn-inner">
                    {children}
                </div>
            </div>
            <div className="chat-actionsctn">
                <Button icon={<MailOutlined />} onClick={_onMessagerie}>Messagerie</Button>
                <Button icon={<ClockCircleOutlined />} onClick={setRappeler} >A rappeler</Button>
            </div>
            <div className="chat-sendmsgctn">
                <Input.Group compact>
                    <Input prefix={<MessageOutlined />} value={comment} onPressEnter={_onAddComment} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)} style={{ width: 'calc(100% - 32px)' }}/>
                    <Tooltip title="Ajouter un commentaire">
                        <Button icon={<SendOutlined />} onClick={_onAddComment} type="primary" />
                    </Tooltip>
                </Input.Group>
            </div>
        </div>
    )
}

export default ChatContainer;