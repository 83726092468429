import React, { useState, useEffect } from "react";
import { useLogin } from "@pankod/refine-core";
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Form,
  Input,
  Button
} from "@pankod/refine-antd";

import ReactCodeInput from "react-verification-code-input";

const { Title } = Typography;

export interface ILoginForm {
  email: string;
  password: string;
  code?: string;
}

export const Login: React.FC = () => {
  const [code, showCode] = useState (false);  
  const [form] = Form.useForm<ILoginForm>();

  const { mutate: login, error } = useLogin<ILoginForm>();
  
  useEffect (() => {
    if (error && (error.message === "Code invalide" || error.message === "Adresse IP inconnue"))
        showCode (true);
  }, [error])


  const CardTitle = (
    <Title level={3} className="title">
     EMG Dashboard
    </Title>
  );

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh"
        }}
      >
        <Col xs={22}>
          <div className="container">
            {/* <div className="imageContainer">
              <img src="./refine.svg" alt="Refine Logo" />
            </div> */}
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={(values) => {
                    login (values);
                }}
                requiredMark={false}
              >
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="Email" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Mot de passe"
                  rules={[{ required: true }]}
                  style={{ marginBottom: "12px" }}
                >
                  <Input type="password" placeholder="●●●●●●●●" size="large" />
                </Form.Item>

                {code && 
                    <Form.Item
                        name="code"
                        label="Code de vérification"
                        rules={[{ required: code }, {len: 4}]}
                        style={{ marginBottom: "12px" }}
                    >
                        <ReactCodeInput
                            type='number'
                            fields={4}
                        />
                    </Form.Item>
                }
                <Button type="primary" size="large" htmlType="submit" block style={{ marginTop: "12px" }}>
                  Se connecter
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
