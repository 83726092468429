import { BaseKey, useAuthenticated, useCreate, useGetIdentity, useUpdate } from "@pankod/refine-core";
import {
    Modal,
    Row,
    Col,
    Typography,
    Tag,
    DatePicker,
    Input,
    Button
} from "@pankod/refine-antd";

import { ICommentaire, ILead, IRappel } from "interfaces";
import React, { ReactElement, useState } from "react";
import Verified from "../../../assets/verif.png";
import ChatContainer from "components/data/ChatContainer";
import ChatMessage from "components/data/ChatMessage";
import dayjs, { Dayjs } from "dayjs";

import "dayjs/locale/fr";
import localpicker from "antd/es/date-picker/locale/fr_FR";
import { statutsLeads } from "utils/statutleads";
import { formatAmount, formatTelephone } from "utils/functions";

const { Title, Paragraph, Text } = Typography;
const { TextArea } = Input;

interface Props {
    visible: boolean;
    onCancel: () => void;
    record?: ILead;
    showId?: BaseKey
}

const ModalLead: React.FC<Props> = ({ visible, onCancel, record, showId }) => {
    const { data: identity } = useGetIdentity();

    const typeMapping: Record<string, string> = {
        ["recherche avoirs"]: "Recherche d'avoirs",
        ["placement avoirs"]: "Placement d'avoirs",
    }

    const datenaissance = dayjs(record?.datenaissance, 'YYYY');
    const age = Math.trunc(dayjs().diff(datenaissance, 'years', true));

    const { mutate } = useUpdate();
    const setStatut = (statut: string) => {
        if (!showId) {
            return;
        }
        mutate({
            resource: "leads",
            id: showId,
            values: { statut }
        })
    }
    const addComment = (c: string) => {
        if (!showId) {
            return;
        }
        const commentaire: ICommentaire = {
            idorigin: identity.id,
            origin: identity.prenom ?? "Unknown",
            timestamp: dayjs().valueOf(),
            message: c
        }
        mutate({
            resource: "leads",
            id: showId,
            values: { commentaires: [...record?.commentaires ?? [], commentaire] }

        })
    }
    const renderCommentaires = () => {
        let jsxcomments: ReactElement[] = [];
        record?.commentaires.forEach(c => {
            const owncomment = c.idorigin === identity.id;
            jsxcomments.push(
                <ChatMessage
                    date={dayjs(c.timestamp).format("DD/MM HH:mm")}
                    origin={c.origin}
                    message={c.message}
                    ownMessage={owncomment}
                    key={c.timestamp}
                />
            )
        })
        return jsxcomments;
    }

    //Modal a rappeler
    const { mutate: create } = useCreate<IRappel>();
    const [modalrappeler, setModalrappeler] = useState(false);
    const setRappeler = () => {
        setModalrappeler(true);
    }
    const [daterappel, setDaterappel] = useState(dayjs());
    const handleDateChange = (dateObj: Dayjs | null): void => {
        if (dateObj)
            setDaterappel(dateObj);
    }
    const [commentrappel, setCommentrappel] = useState("");
    const addRappel = () => {
        const newrappel: IRappel = {
            _id: "",
            date: daterappel.toDate(),
            comment: commentrappel,
            iduser: identity.id,
            idlead: showId as string,
            statut: 0
        }
        setStatut("A rappeler")
        create({
            resource: "rappels",
            values: newrappel
        })
        setCommentrappel("");
        setDaterappel(dayjs());
        setModalrappeler(false);
    }

    const [modalsignature, setModalsignature] = useState(false);

    return (
        <>
            <Modal
                visible={visible}
                onCancel={onCancel}
                title="Lead"
                width={800}
                style={{ top: 40 }}
                footer={false}
            >
                {record && <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Row>
                            <Col span={24}>
                                <Title level={4} style={{ marginBottom: 15 }}>Informations</Title>
                                <Row>
                                    <Col xs={24}>
                                        <Paragraph>Nom : <b>{record.prenom ?? "Inconnue"}</b>  <b>{record.nom ?? "Inconnue"}</b></Paragraph>
                                        {record.extra?.sexe && <Paragraph>Genre : <b>{record.extra?.sexe.title ?? record.extra?.sexe.value ?? "Inconnue"}</b></Paragraph>}
                                        {datenaissance.isValid() &&
                                            <Paragraph>Age : <b>{age} ans</b></Paragraph>
                                        }
                                        <Paragraph>Numéro : <b>{formatTelephone(record.telephone ?? "")}</b> {record.verified && <img src={Verified} alt="verified" style={{ height: 17 }} />}</Paragraph>
                                        {record.infoscomp &&
                                            <>
                                                {record.infoscomp.numavs ? <Paragraph>AVS : <b>{record.infoscomp.numavs}</b></Paragraph> : null}
                                                {record.infoscomp.adresse ? 
                                                    <Paragraph>Adresse : <b>{record.infoscomp.adresse}, {record.infoscomp.localite}</b></Paragraph> 
                                                : null}
                                                {record.infoscomp.signature ? <Button size="small" style={{marginBottom: 8}} onClick={() => setModalsignature(true)}>Voir signature</Button> : null}
                                            </>
                                        }
                                        <Paragraph>Type de demande : <b>{record.type && typeMapping[record.type]}</b></Paragraph>
                                        <Paragraph>
                                            <Title level={5}>Profil</Title>
                                            {record.extra?.anneetravaillees ? <Tag style={{ marginRight: 8, marginBottom: 5 }}>{record.extra?.anneetravaillees} années travaillées en Suisse</Tag> : null}
                                            {record.extra?.domiciliation?.id ? <Tag style={{ marginRight: 8, marginBottom: 5 }}>{record.extra?.domiciliation.title ?? record.extra?.domiciliation.value}</Tag> : null}
                                            {record.extra?.numavs ? <Tag style={{ marginRight: 8, marginBottom: 5 }}>AVS: {record.extra?.numavs}</Tag> : null}
                                            {record.extra?.occupation?.id ? <Tag style={{ marginRight: 8, marginBottom: 5 }}>{record.extra?.occupation.title ?? record.extra?.occupation.value}</Tag> : null}
                                            {record.extra?.origindemande?.id ? <Tag style={{ marginRight: 8, marginBottom: 5 }}>{record.extra?.origindemande.title ?? record.extra?.origindemande.value}</Tag> : null}
                                            {record.extra?.rechercheemploi?.id && record.extra?.rechercheemploi?.id === 2 ? <Tag style={{ marginRight: 8, marginBottom: 5 }}>{record.extra?.rechercheemploi.title ?? record.extra?.rechercheemploi.value}</Tag> : null}
                                            {record.extra?.regimeassurance?.id && record.extra?.domiciliation?.id !== 1 ? <Tag style={{ marginRight: 8, marginBottom: 5 }}>{record.extra?.regimeassurance.title ?? record.extra?.regimeassurance.value}</Tag> : null}
                                            {record.extra?.caissepension?.id ? <Tag style={{ marginRight: 8, marginBottom: 5 }}>{record.extra?.caissepension.title ?? record.extra?.caissepension.value}</Tag> : null}
                                            {record.extra?.connaissancemontantlpp?.id ? <Tag style={{ marginRight: 8, marginBottom: 5 }}>{record.extra?.connaissancemontantlpp.title ?? record.extra?.connaissancemontantlpp.value}</Tag> : null}
                                            {record.extra?.connaissancemontantlpp?.id === 1
                                                && record.extra?.montantlpp && typeof record.extra?.montantlpp !== 'object' ?
                                                <Tag style={{ marginRight: 8, marginBottom: 5 }}>Avoirs : {record.extra?.montantlpp} CHF</Tag> : null
                                            }
                                            {record.extra?.connaissancemontantlpp?.id === 1
                                                && record.extra?.montantlpp && typeof record.extra?.montantlpp === 'object'
                                                &&  record.extra?.montantlpp.id !== 5 ?
                                                <Tag style={{ marginRight: 8, marginBottom: 5 }}>{record.extra?.montantlpp.title ?? record.extra?.montantlpp.value}</Tag> : null
                                            }
                                            {record.extra?.connaissancemontantlpp?.id === 1
                                                && record.extra?.montantlpp && typeof record.extra?.montantlpp === 'object'
                                                &&  record.extra?.montantlpp.id === 5 ?
                                                <Tag style={{ marginRight: 8, marginBottom: 5 }}>Avoirs: {formatAmount (parseInt (record.extra?.montantlpp.title ?? record.extra?.montantlpp.value, 10))} CHF</Tag> : null
                                            }
                                            {record.extra?.profilprevoyance?.id ? <Tag style={{ marginRight: 8, marginBottom: 5 }}>Profil prévoyance : {record.extra?.profilprevoyance.title ?? record.extra?.profilprevoyance.value}</Tag> : null}
                                        </Paragraph>
                                        <Paragraph>
                                            <Title level={5}>Raisons</Title>
                                            {!record.extra?.raisons.length ? <Text>Raisons inconnues</Text> : null}
                                            {record.extra?.raisons.map((r: any) => <Tag key={r.id} style={{ marginRight: 8, marginBottom: 5 }}>{r.title ?? r.value}</Tag>)}
                                        </Paragraph>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} md={12}>
                        <Row style={{ marginBottom: 10 }}>
                            <Col span={24}>
                                <Title level={4} style={{ marginBottom: 15 }}>Statut</Title>
                                <Paragraph>Statut actuel : <b>{record.statut}</b></Paragraph>
                                {statutsLeads.map((s) => <Tag onClick={() => setStatut(s.name)} key={s.name} color={s.color} style={{ cursor: "pointer", marginRight: 8, marginBottom: 5 }}>{s.name}</Tag>)}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Title level={4} style={{ marginBottom: 15 }}>Commentaires</Title>
                                <Row>
                                    <Col span={24}>
                                        <ChatContainer
                                            onAddComment={addComment}
                                            commentaires={record.commentaires}
                                            setStatut={setStatut}
                                            setRappeler={setRappeler}
                                        >
                                            {renderCommentaires()}
                                        </ChatContainer>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>}
            </Modal>
            <Modal
                visible={modalrappeler}
                onCancel={() => setModalrappeler(false)}
                title="Programmer rappel"
                onOk={addRappel}
            >
                <DatePicker value={daterappel} onChange={handleDateChange} allowClear={false} format="DD/MM/YYYY HH:mm" locale={localpicker} showTime />
                <TextArea autoSize value={commentrappel} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setCommentrappel(e.target.value)} />
            </Modal>
            {record && record.infoscomp && record.infoscomp.signature && <Modal
                title="Signature"
                visible={modalsignature}
                onCancel={() => setModalsignature(false)}
                onOk={() => setModalsignature(false)}
            >
                <img src={record?.infoscomp.signature} />
            </Modal>}
        </>
    )
}

export default ModalLead;