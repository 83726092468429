import { HttpError } from "@pankod/refine-core";
import { AxiosInstance } from "axios";
import { IAuth } from "interfaces";
import { stringify } from "query-string";
import IDataProvider from "./dataprovider.d";

export const TOKEN_KEY = "refine-auth";

export const setHeaders = (axiosInstance: AxiosInstance) => {
    const authJson = localStorage.getItem(TOKEN_KEY);
    if (!authJson)
        return axiosInstance;
    const auth = JSON.parse (authJson) as IAuth;
    axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${auth.token}`,
    };
    return axiosInstance;
}

export const setErrorInterceptor = (axiosInstance: AxiosInstance) => {
    axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
            const customError: HttpError = {
                ...error,
                message: error.response?.data?.message,
                statusCode: error.response?.status,
            };
            return Promise.reject(customError);
        },
    );
    return axiosInstance;
}

const dataProvider = (
    apiUrl: string,
    httpClient: AxiosInstance,
): IDataProvider => ({
    create: async ({ resource, variables, metaData }) => {
        httpClient = setErrorInterceptor (setHeaders (httpClient));
        const url = `${apiUrl}/${resource}`
        const { data } = await httpClient.post (url, variables);
        return data;
    },
    createMany: ({ resource, variables, metaData }) => Promise.resolve (""),
    deleteOne: ({ resource, id, metaData }) => Promise.resolve (""),
    deleteMany: ({ resource, ids, metaData }) => Promise.resolve (""),
    getList: async ({ resource, pagination, filters, sort }) => {
        httpClient = setErrorInterceptor (setHeaders (httpClient));
        const params = {
            pagination: JSON.stringify (pagination),
            filters: JSON.stringify (filters),
            sort: JSON.stringify (sort),
        }
        const url = `${apiUrl}/${resource}?${stringify (params)}`;
        const { data } = await httpClient.get(`${url}`);
        return {
            data: data[resource],
            total: data.count,
        };
    },
    getMany: ({ resource, ids, metaData }) => Promise.resolve (""),
    getOne: async ({ resource, id, metaData }) => {
        httpClient = setErrorInterceptor (setHeaders (httpClient));
        const url = `${apiUrl}/${resource}/${id}`
        const { data } = await httpClient.get (url);
        return data;
    },
    update: async ({ resource, id, variables, metaData }) => {
        httpClient = setErrorInterceptor (setHeaders (httpClient));
        const url = `${apiUrl}/${resource}/${id}`
        const { data } = await httpClient.patch (url, variables);
        return data;
    },
    updateMany: ({ resource, ids, variables, metaData }) => Promise.resolve (""),
    getApiUrl: () => apiUrl,
});

export default dataProvider;