import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  ShowButton,
  TagField,
  EmailField
} from "@pankod/refine-antd";

import { IUser } from "interfaces";

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const paramsUrl = new URLSearchParams (window.location.search);
  const { tableProps } = useTable<IUser>({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    permanentFilter: [
    ],
    initialPageSize: parseInt (paramsUrl.get ("pageSize") ?? "50", 10),
    initialCurrent: parseInt (paramsUrl.get ("current") ?? "1", 10)
  });

  return (
    <List title="Users">
        <Table {...tableProps} rowKey="_id">
            <Table.Column
            dataIndex="name"
            key="name"
            title="Nom"
            render={(value) => <TextField value={value} />}
            />
            <Table.Column
            dataIndex="firstname"
            key="firstname"
            title="Prénom"
            render={(value) => <TextField value={value} />}
            />
            <Table.Column
            dataIndex="email"
            key="email"
            title="E-mail"
            render={(value, record : IUser) => <EmailField value={value} />}
            />
            <Table.Column
            dataIndex="phone"
            key="phone"
            title="Téléphone"
            render={(value, record : IUser) => <TagField value={value} />}
            />
            <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_, record : IUser) => 
                <ShowButton hideText recordItemId={record._id} />
            }
            />
        </Table>
    </List>
  );
};
