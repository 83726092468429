import { ILead } from "interfaces";
import React from "react";
import Verified from "../../../assets/verif.png";
import {red} from "@ant-design/colors";
import { Tooltip } from "@pankod/refine-antd";
import { formatTelephone } from "utils/functions";

interface PropsNumField {
    value: string;
    record: ILead;
}

const NumField : React.FC<PropsNumField> = ({value, record}) => {
    const isverified = record.verified;
    const doublon = record.doublontel;
    
    return(
        <Tooltip title={doublon ? "Doublon téléphone" : ""} trigger={doublon ? "hover" : "focus"}>
            <span style={{color: doublon ? red.primary : "#626262"}}>{formatTelephone (value)}</span>
            {isverified && 
                <span><img src={Verified} alt="verified" style={{marginLeft: 10, height: 17}}/></span>
            }
        </Tooltip>
    )
}

export default NumField;