import { Tag } from "@pankod/refine-antd";
import React from "react";
import { statutsLeads } from "utils/statutleads";

interface Props {
    value: string;
}

const TypeField : React.FC<Props> = ({value}) => {
    const optionsType = statutsLeads.find (s => s.name === value)

    return(
        <Tag color={optionsType?.color}>{value}</Tag>
    )
}

export default TypeField;